import { useAuthStore } from "@/state/modules/auth";
import {computed} from "vue";


export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/modules/auth/views/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                const authStore = useAuthStore()
                const userAuthorised = computed(() => authStore.isAuthorised)
                // If the user is already logged in
                if (userAuthorised.value) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout", authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("@/modules/auth/views/logout")
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("@/modules/auth/views/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                const authStore = useAuthStore()
                const userAuthorised = computed(() => authStore.isAuthorised)
                // If the user is already logged in
                if (userAuthorised.value) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
];
import { ref } from "vue";
import { defineStore } from 'pinia'
import { Request } from '@/http/request'
import { getEventMessages } from '@/helpers/event-messages'
import { fillMessages, getErrorMessage } from '@/helpers/handler-messages'

const API_URL = process.env.VUE_APP_CATALOG_API_URL

export const useShowcaseStore = defineStore('showcase', () => {
    // state
    const showcases = ref([])
    const total = ref(0)
    const page = ref(1)

    const filters = ref({})

    const apiErrorMessage = ref(null)
    const requested = ref(false)

    const messages = ref( getEventMessages() )

    // actions
    function initStore(force = false, filter = {}) {
        if (!showcases.value.length || force) {
            return loadShowcases(filter)
        }
        return Promise.resolve(true)
    }

    function loadShowcases(filter = {}) {
        if (requested.value) {
            return Promise.resolve(false)
        }
        apiErrorMessage.value = null
        requested.value = true

        const request = new Request()

        return request.send({
            url: API_URL + '/api/showcases',
            method: 'get',
            params: filter
        }).then(res => {
            showcases.value = res.data['hydra:member'] || []
            page.value = res?.data['hydra:view']?.['@id']
            total.value = res?.data['hydra:totalItems']

            if (page.value) {
                page.value = page.value.substring(page.value.indexOf('page=') + 5)
            }
            return true
        }).catch((error) => {
            fillMessages(messages.value, {
                text: getErrorMessage(error),
                action: 'get',
                type: 'danger'
            })
    
            return error
        }).finally(() => requested.value = false)
    }

    function getShowcase(id) {
        return id && showcases.value && showcases.value.find((el) => el.id == id) || null
    }

    function findShowcaseByIri(iri) {
        return iri && showcases.value && showcases.value.find((el) => el['@id'] == iri) || null
    }

    function $reset() {
        requested.value = false
        messages.value = []
    }

    return {
        showcases,
        total,
        requested,
        messages,
        filters,

        initStore,
        loadShowcases,
        getShowcase,
        findShowcaseByIri,

        $reset
    }
})
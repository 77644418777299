export default [
  {
    path: "/dictionary-color",
    name: "dictionary-color",
    meta: {
      title: "Естественные цвета глаз",
      authRequired: true,
    },
    component: () => import("@/modules/dictionary/colors/view/color-list.vue"),
  },
];
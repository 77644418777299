export default [
    {
        path: "/categories",
        name: "categories",
        meta: {
            title: "Категории",
            authRequired: true,
        },
        component: () => import("@/modules/category/views/categories.vue"),
    },
    {
        path: "/categories/:id",
        name: "category-edit",
        meta: {
            title: "Категория",
            authRequired: true,
        },
        component: () => import("@/modules/category/views/category-edit.vue"),
    },
    {
        path: "/categories/new",
        name: "category-create",
        meta: {
            title: "Категория",
            authRequired: true,
        },
        component: () => import("@/modules/category/views/category-edit.vue"),
    },
];
import mapErrors from '@/helpers/map-errors'

function fillMessages(messagesList, options = {
  text: '',
  action: null,
  type: 'success'
}) {
  if (!options.action) return

  messagesList[options.action] = {
    message: options.text,
    isShow: true,
    type: options.type
  }
}

function getErrorMessage(error) {
  let message = 'Неполадки на сервере'

  if (error.response?.data?.detail) {
    message = (error.response.data.status === 500) ? error.message : error.response.data.detail
  }

  return message
}

function fillValidationNotes(messagesList, error) {
  if (error.response?.data?.status === 422) {
    messagesList.validate = mapErrors(error.response?.data?.violations)
  }
}

/**
 * @param {Object} messages Объект для вывода сообщений в правой колонке
 * @param {Array} tabs Массив табов для вадидации
 * @param {Object} tab Таб для вадидации
 * @param {String} tab.title Заголовок таба
 * @param {String} tab.typeMessage Тип сообщения для таба (ключ в объекте "messages")
 * @param {Array} tab.fields Список названий валидируемых полей таба
 */

function getErrorTabsMessage( messages = {}, tabs = [] ) {
  const validate = messages.validate || {}
  const validateFields = Object.keys( validate )

  tabs.forEach( (tab) => {
    const tabError = !!tab.fields.filter( (field) => validateFields.includes(field) ).length
    
    if ( tabError ) {
      fillMessages( messages, {
        text: `Не удалось сохранить данные, проверьте правильность заполнения полей на вкладке <b>&#34;${tab.title}&#34;</b>`,
        action: tab.typeMessage,
        type: 'danger'
      })
    }
  })
}

function getMassFailures(result, items) {
  if ( !result) {
    return items
  }

  return items.filter((item) => {
    return result.every((resultItem) => resultItem.id !== item.id)
  })
}

/**
* @param {error} error ошибка
* @param {String} actionName свойство объекта messages, в которое запишется результат обработки
* @param {Ref} messages ссылка на объект с сообщениями, куда записывать результат
 **/
function handleError(error, actionName, messages) {
  const message = getErrorMessage(error)
  const messageOptions = { text: message, action: actionName, type: 'danger' }

  fillMessages(messages.value, messageOptions)
}

function handleSuccess (text, actionName, messages) {
  const messageOptions = { text: text, action: actionName, type: 'success' }

  fillMessages(messages.value, messageOptions)
}

export {
  handleError,
  fillMessages,
  handleSuccess,
  getMassFailures,
  getErrorMessage,
  fillValidationNotes,
  getErrorTabsMessage
}
import Axios from 'axios'
import router from '@/router'

const settings = {
  url: '',
  method: 'GET',
  data: null
}

export class Request {
  constructor( options = {} ) {
    this.options = options
    this.options.__proto__ = settings
  }

  addHeaderToken(axios, tokenKey = 'access_token') {
    // Подстановка токена для основного запроса
    axios.interceptors.request.use( function( config ) {
      let token = localStorage.getItem( tokenKey )
      if ( token ) config.headers.Authorization = 'Bearer ' + token

      Axios.defaults.headers[ 'Accept' ] = 'application/ld+json'
      config.headers['Accept'] = 'application/ld+json'

      if (config.method === 'patch') {
        config.headers['Content-Type'] = 'application/merge-patch+json'
        config.headers['Accept'] = 'application/ld+json'
      }

      return config
    },
    function( error ) {
      return Promise.reject( error );
    });
  }

  send( params = {} ) {
    const context = this;

    let options = Object.assign( {}, params );
    options.__proto__ = settings;
    context.options = options;

    const axios = Axios.create()
    this.addHeaderToken(axios);

    // Response interceptor
    axios.interceptors.response.use( function( response ) {
      return response
    },
    async function( error ) {
      if ( error && error.response && error.response.status === 401 ) {
        router.push({path: '/login'})
      } else {
        return Promise.reject(error);
      }
    })

    // Request
    function mainRequest() {
      return axios(context.options)
          .then((response) => {
            return response
          })
    }
    return mainRequest();
  }
}
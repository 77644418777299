function getEventMessages() {
  return {
    get: {
      type: 'success',
      isShow: false,
      message: '',
    },
    edit: {
      type: 'success',
      isShow: false,
      message: '',
    },
    create: {
      type: 'success',
      isShow: false,
      message: '',
    },
    delete: {
      type: 'success',
      isShow: false,
      message: '',
    },
    validate: {}
  }
}

export {getEventMessages}
export default [
    {
        path: "/showcase-list",
        name: "showcase-list",
        meta: {
            title: "Список витрин",
            authRequired: true,
        },
        component: () => import("@/modules/showcase/views/showcase-list.vue"),
    },
    {
        path: "/showcase-list/:id",
        name: "showcase-edit",
        meta: {
            title: "Витрина",
            authRequired: true,
        },
        component: () => import("@/modules/showcase/views/showcase-edit.vue"),
    },
    {
        path: "/showcase-list/new",
        name: "showcase-create",
        meta: {
            title: "Витрина",
            authRequired: true,
        },
        component: () => import("@/modules/showcase/views/showcase-edit.vue"),
    },
];
<template>
 <router-view></router-view>

</template>

<script>

import {useShowcaseStore} from "@/modules/showcase/state/showcase";

export default {
  name: 'App',
  components: {
  },
  created() {
    const showcaseStore = useShowcaseStore()
    showcaseStore.initStore()
  }
}
</script>

<style lang="scss">
.v-modal-fluid {
  .modal-dialog {
    @media (min-width: 1024.1px) {
      min-width: 90%;
    }
  }
  .modal-content {
    @media (min-width: 1024.1px) {
      background-color: var(--vz-body-bg);
    }
  }
}
</style>

import axios from 'axios'

/**
 * KeyCloak API functions for authorisation user info and refresh tokens
 */

/**
 * Login and get token
 * @param username
 * @param password
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function logIn(username, password) {
    const API_AUTH_URL = process.env.VUE_APP_AUTH_API_URL
    const APP_AUTH_REALM = process.env.VUE_APP_AUTH_REALM
    const client_id = process.env.VUE_APP_AUTH_CLIENTID
    const client_secret = process.env.VUE_APP_AUTH_CLIENT_SECRET
    const grant_type = 'password'

    return axios.post(`${API_AUTH_URL}/auth/realms/${APP_AUTH_REALM}/protocol/openid-connect/token`, {
        username, password, client_id, client_secret, grant_type
    }, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
}

/**
 * Logout user
 * @param refresh_token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function logOut(refresh_token) {
    const API_AUTH_URL = process.env.VUE_APP_AUTH_API_URL
    const APP_AUTH_REALM = process.env.VUE_APP_AUTH_REALM
    const client_id = process.env.VUE_APP_AUTH_CLIENTID
    const client_secret = process.env.VUE_APP_AUTH_CLIENT_SECRET

    return axios.post(`${API_AUTH_URL}/auth/realms/${APP_AUTH_REALM}/protocol/openid-connect/logout`, {
        client_id, client_secret, refresh_token
    }, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
}

/**
 * Get new token via refresh token
 * @param refresh_token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function refreshToken(refresh_token) {
    const API_AUTH_URL = process.env.VUE_APP_AUTH_API_URL
    const APP_AUTH_REALM = process.env.VUE_APP_AUTH_REALM
    const client_id = process.env.VUE_APP_AUTH_CLIENTID
    const client_secret = process.env.VUE_APP_AUTH_CLIENT_SECRET
    const grant_type = 'refresh_token'

    return axios.post(`${API_AUTH_URL}/auth/realms/${APP_AUTH_REALM}/protocol/openid-connect/token`, {
        client_id, client_secret, refresh_token, grant_type
    }, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
}

/**
 * Get user info
 * @param access_token
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function userInfo(access_token) {
    const API_AUTH_URL = process.env.VUE_APP_AUTH_API_URL
    const APP_AUTH_REALM = process.env.VUE_APP_AUTH_REALM

    return axios.get(`${API_AUTH_URL}/auth/realms/${APP_AUTH_REALM}/protocol/openid-connect/userinfo`, {
        headers: { 'Authorization': 'Bearer ' + access_token }
    })
}

export function getForgotPasswordUrl() {
    const API_AUTH_URL = process.env.VUE_APP_AUTH_API_URL
    const APP_AUTH_REALM = process.env.VUE_APP_AUTH_REALM
    const client_id = process.env.VUE_APP_AUTH_CLIENTID
    const response_mode = 'fragment'
    const response_type = 'code'
    const scope = 'openid'
    const redirect_uri = process.env.VUE_APP_URL

    const searchParams = new URLSearchParams({
        client_id,
        response_mode,
        response_type,
        scope,
        redirect_uri,
        back_uri: redirect_uri
    });

    return `${API_AUTH_URL}/auth/realms/${APP_AUTH_REALM}/protocol/openid-connect/forgot-credentials?${searchParams}`
}
export default [
  {
    path: "/stock",
    name: "stock",
    meta: {
      title: "Сток",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/stock/views/stock.vue"),
  },
  {
    path: "/stock/:id",
    name: "stock-product",
    meta: {
      title: "Товар",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/stock/views/stock-product.vue"),
  },
];
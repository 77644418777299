export default [
    {
        path: "/characteristics-groups",
        name: "characteristics-groups",
        meta: {
            title: "Группы характеристик",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/CharacteristicsGroups/views/CharacteristicsGroupsList.vue"),
    },
    {
        path: "/characteristics-groups/:id",
        name: "characteristic-group-view",
        meta: {
            title: "Группа характеристик",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/CharacteristicsGroups/views/CharacteristicsGroup.vue"),
    },
    {
        path: "/characteristics-groups/new",
        name: "characteristic-group-create",
        meta: {
            title: "Новая группа характеристик",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/CharacteristicsGroups/views/CharacteristicsGroup.vue"),
    },
]
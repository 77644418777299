export default [
  {
    path: '/dictionary-lens-color',
    name: 'dictionary-lens-color',
    meta: {
      title: 'Желаемые цвета глаз',
      authRequired: true,
    },
    component: () => import('@/modules/dictionary/lens-color/view/lens-color-list.vue'),
  },
]
export default [
  {
    path: "/warehouses",
    name: "warehouses",
    meta: {
      title: "Склады",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/warehouses/views/warehouses.vue"),
  },
  {
    path: "/warehouses/:id",
    name: "warehouse-edit",
    meta: {
      title: "Склад",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/warehouses/views/warehouse-edit.vue"),
  },
];
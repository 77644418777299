export default [
    {
        path: "/products",
        name: "products",
        meta: {
            title: "Товары",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-product/views/products.vue"),
    },
    {
        path: "/products/:id",
        name: "product-edit",
        meta: {
            title: "Товар",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-product/views/product-edit.vue"),
    },
    {
        path: "/products/new",
        name: "product-create",
        meta: {
            title: "Товар",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-product/views/product-edit.vue"),
    },
];
export default [
    {
        path: "/showcase-characteristics",
        name: "showcase-characteristics",
        meta: {
            title: "Характеристики",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-characteristics/views/showcase-characteristics.vue"),
    },
    {
        path: "/showcase-characteristics/:id",
        name: "showcase-characteristics-edit",
        meta: {
            title: "Характеристика витрины",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-characteristics/views/showcase-characteristics-view.vue"),
    },
    {
        path: "/showcase-characteristics/new",
        name: "showcase-characteristics-create",
        meta: {
            title: "Характеристика витрины",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/showcase-characteristics/views/showcase-characteristics-view.vue"),
    },
];
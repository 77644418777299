export default [
    {
        path: "/global-products",
        name: "global-products",
        meta: {
            title: "Глобальные товары",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/global-product/views/products.vue"),
    },
    {
        path: "/global-products/:id",
        name: "global-products-view",
        meta: {
            title: "Глобальный товар",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/global-product/views/product-view.vue"),
    },
    {
        path: "/global-products/products",
        name: "global-product-products",
        meta: {
            title: "Товары",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/global-product/views/global-product-products.vue"),
    },
];
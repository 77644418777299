export default [
  {
    path: "/options",
    name: "options",
    meta: {
      title: "Опции",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/options/views/options.vue"),
  },
  {
    path: "/options/:id",
    name: "option-edit",
    meta: {
      title: "Опция",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/options/views/option-edit.vue"),
  },
];
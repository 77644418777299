export default [
  {
    path: "/filters",
    name: "filters",
    meta: {
      title: "Фильтры",
      authRequired: true,
    },
    component: () => import("@/modules/filters/view/filter-list.vue"),
  },
  {
    path: "/filters/:id",
    name: "filters-edit",
    meta: {
      title: "Фильтр",
      authRequired: true,
    },
    component: () => import("@/modules/filters/view/filter-edit.vue"),
  },
  {
    path: "/filters/new",
    name: "filters-create",
    meta: {
      title: "Создание фильтра",
      authRequired: true,
    },
    component: () => import("@/modules/filters/view/filter-edit.vue"),
  },
]
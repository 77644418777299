import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import {useAuthStore} from "@/state/modules/auth";
import {computed} from "vue";

let userStoreInitialised = false


const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  const authStore = useAuthStore()
  if (!userStoreInitialised) {
    userStoreInitialised = true
    authStore.initStore()
  }
  const publicPages = ['/login', '/register', '/forgot-password'];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = computed(() => authStore.isAuthorised);

  if (authpage && !loggeduser.value) {
    return next('/login');
  }

  next();

});


export default router;

export default [
  {
    path: "/advantages",
    name: "advantages",
    meta: {
      title: "Преимущества",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/advantages/views/advantages.vue"),
  },
  {
    path: "/advantages/:id",
    name: "advantage-edit",
    meta: {
      title: "Преимущество",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/advantages/views/advantage-edit.vue"),
  },
  {
    path: "/advantages/new",
    name: "advantage-create",
    meta: {
      title: "Преимущество",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/advantages/views/advantage-edit.vue"),
  },
  {
    path: "/advantages/products",
    name: "advantage-products",
    meta: {
      title: "Товары",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/advantages/views/advantage-products.vue"),
  },
];
export default [
  {
    path: "/tags",
    name: "tags",
    meta: {
      title: "Теги",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/tags/views/tags.vue"),
  },
  {
    path: "/tags/:id",
    name: "tag-edit",
    meta: {
      title: "Тег",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/tags/views/tag-edit.vue"),
  },
  {
    path: "/tags/new",
    name: "tag-create",
    meta: {
      title: "Тег",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/tags/views/tag-edit.vue"),
  },
  {
    path: "/tags/products",
    name: "tag-products",
    meta: {
      title: "Товары",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/tags/views/tag-products.vue"),
  },
];
import { ref, computed } from "vue";
import { defineStore } from 'pinia'
import { logIn, logOut, refreshToken, userInfo } from '@/http/auth_api/auth'

export const useAuthStore = defineStore('user', () => {
    // state
    const access_token = ref(localStorage.getItem('access_token'))
    const access_token_expire_at = ref(localStorage.getItem('access_token_expire_at'))
    const refresh_token = ref(localStorage.getItem('refresh_token'))
    const refresh_token_expire_at = ref(localStorage.getItem('refresh_token_expire_at'))
    const user = ref( JSON.parse(localStorage.getItem('user')) )
    const userApiErrorMessage = ref(null)
    const userRequest = ref(false)


    // getters
    const accessIsExpired = computed( () => access_token_expire_at.value - Math.ceil(Date.now() / 1000) <= 0)
    const refreshIsExpired = computed( () => refresh_token_expire_at.value - Math.ceil(Date.now() / 1000) <= 0)
    const isAuthorised = computed( () => access_token.value && access_token_expire_at.value - Math.ceil(Date.now() / 1000) > 0)
    const userName = computed(() => user.value && user.value.name)

    const refreshTimeout = ref(null)

    function initStore() {
        userRequest.value = false
        userApiErrorMessage.value = null
        return initRefreshTimer()
    }

    // actions
    function updateTokenData(data) {
        if (data.access_token) {
            localStorage.setItem('access_token', data.access_token)
            access_token.value = data.access_token
        }

        if (data.refresh_token) {
            localStorage.setItem('refresh_token', data.refresh_token)
            refresh_token.value = data.refresh_token
        }

        if (data.expires_in) {
            access_token_expire_at.value = Math.ceil(Date.now() / 1000) + data.expires_in - 2
            localStorage.setItem('access_token_expire_at', access_token_expire_at.value)
        }

        if (data.refresh_expires_in) {
            refresh_token_expire_at.value = Math.ceil(Date.now() / 1000) + data.refresh_expires_in - 2
            localStorage.setItem('refresh_token_expire_at', refresh_token_expire_at.value)
        }
        initRefreshTimer()
    }

    /**
     * Check access and refresh expire timers to detect time when need to get a fresh token.
     * When access token time is expired
     */
    function initRefreshTimer() {
        if (refreshTimeout.value) {
            clearTimeout(refreshTimeout.value)
            refreshTimeout.value = null
        }
        let timer = access_token_expire_at.value - Math.ceil(Date.now() / 1000)
        if (timer <= 0) {
            let refreshDiff = refresh_token_expire_at.value - Math.ceil(Date.now() / 1000)
            if (refreshDiff > 0) {
                // refresh now
                return refreshUserToken()
            } else if (refreshDiff < 0) {
                // access data too old
                clearLocalStorageData()
            }
        }
        if (timer >= 0) {
            if (timer > 30) {
                timer -= 30
            }
            refreshTimeout.value = setTimeout(refreshUserToken, timer * 1000)
        }
        return Promise.resolve(true)
    }

    function refreshUserToken() {
        return refreshToken(refresh_token.value).then(res => {
            if (res.data && res.status === 200) {
                updateTokenData(res.data)
                return true
            }
        }).catch((error) => {
            if (error.response.status !== 401) {
                console.error('refreshUserToken error', error.response)
            }
            return false
        })
    }


    function clearLocalStorageData() {
        console.log('clearLocalStorageData')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token_expire_at')
        localStorage.removeItem('refresh_token_expire_at')
        localStorage.removeItem('user')
    }

    function clearData() {
        user.value = null
        access_token.value = null
        access_token_expire_at.value = null
        refresh_token.value = null
        refresh_token_expire_at.value = null
    }

    function userLogin(username, password) {
        if (userRequest.value) {
            return Promise.resolve(false)
        }
        if (!username || !password) {
            userApiErrorMessage.value = 'Введите логин и пароль'
            return Promise.resolve(false)
        }
        userApiErrorMessage.value = null
        userRequest.value = true

        return logIn(username, password).then(res => {
            if (res.data && res.status === 200) {
                updateTokenData(res.data)
                getUserInfo()
                return true
            }
            console.error('userLogin unexpected error', res.data);
            return false
        }).catch((error) => {
            if (error.response.status !== 401) {
                console.error('userLogin error', error.response.data)
            }
            userApiErrorMessage.value = 'Неверный логин или пароль'
            return false
        }).finally((res) => {
            userRequest.value = false
            return res
        })
    }

    function userLogout() {
        if (userRequest.value) {
            return Promise.resolve(false)
        }
        userRequest.value = true

        return logOut(refresh_token.value).then(res => {
            if (res.status === 204) {
                clearLocalStorageData()
                clearData()
                return true
            }
            console.error('userLogout unexpected error', res);
            return false
        }).catch(() => {
            return true
        }).finally((res) => {
            userRequest.value = false
            return res
        })
    }

    function getUserInfo() {
        return userInfo(access_token.value).then(res => {
            if (res.data && res.status === 200) {
                let item = res.data
                localStorage.setItem('user', JSON.stringify(item))
                user.value = item
                return true
            }
            console.log('getUserInfo getUserInfo', res)
            return false
        }).catch(error => {
            console.log('getUserInfo error', error)
            if (error.response.status !== 401) {
                console.error('getUserInfo error', error.response)
            }
            return false
        })
    }

    return {
        access_token,
        access_token_expire_at,
        refresh_token,
        refresh_token_expire_at,
        userApiErrorMessage,
        userRequest,

        accessIsExpired,
        refreshIsExpired,
        isAuthorised,

        user,
        userName,

        initStore,
        userLogin,
        userLogout,
        getUserInfo,
    }
})
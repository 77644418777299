export default [
    {
        path: "/global-characteristics",
        name: "global-characteristics",
        meta: {
            title: "Основные характеристики",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/global-characteristics/views/global-characteristics.vue"),
    },
    {
        path: "/global-characteristics/:id",
        name: "global-characteristics-view",
        meta: {
            title: "Глобальная характеристика",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/global-characteristics/views/global-characteristics-view.vue"),
    }
];